import { Typography, Box} from "@mui/material";
import mandelbrot from '../assets/mandelbrot.png'

const About = () => {
    return (
        <Box sx={{ml:'80px'}}>
            <Typography variant='h3'>About</Typography>
            <Box sx={{display:'flex', 
            flexDirection:'column',
            justifyContent:'flex-end', }}>
                <Typography variant="body1">
                    I'm Bryan Gilbert Davis, aka GilbertGravis. I usually work at the intersection of data and engineering. 
                    This page is primarily an exercise in learning React and other front-end tools. It's been great for that, 
                    but it's unlikely that I will keep it up to date.
                </Typography>
                <br/>
                <Typography variant="body1">
                    I'm also a fan of fractals. Here's a Mandelbrot I generated in the process of learning Rust. Feel free to download it and 
                    use it as a wallpaper.
                </Typography>
                <Box component="img" src={mandelbrot} sx={{width:'70%', borderRadius:'5%', m:"5%"}}/>
            </Box>

            
        </Box>
    )

}
export default About;