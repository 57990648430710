import { Box } from "@mui/material";
import KeyLogger from "./KeyLogger";

function Utilities() {
    return (
        <Box sx={{display:'flex', 
        flexDirection:'column',
        justifyContent:'flex-start',
        padding:2}}>
            <KeyLogger/>
        </Box>
    )
}

export default Utilities;