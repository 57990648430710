import React from 'react';
import { Box, Button, Typography, FormLabel, TextField } from '@mui/material';
import { Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';

import { send } from 'emailjs-com';



interface Errors {
    email: string;
    message: string;
}

interface Values {
    email: string;
    message: string;
}



const EmailMeForm = () => {

    const onSubmit = () => {

        // send email to me
        // send('service_1j8q7xg', 'template_1j8q7xg', {
        //     from_name: e.target.email.value,
        //     message: e.target.message.value,
        // },

        alert('button clicked, but the email is')

      };

  return (
    <Formik
        initialValues={{email: '', message: ''}}
        validationSchema={Yup.object({
        email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        message: Yup.string()
            .min(10, "Must have a message")
            .required('Required'),

        })}
        onSubmit={onSubmit}
    >
    <Form>
    <Box sx={{display:'flex', flexDirection:'column',
        justifyContent:'center', alignItems:'center', width:'100%'}}>
    <Typography variant='h6' sx={{p:1}}>Your email address</Typography>
        <TextField
            id="email"
            name="email"
            type="email"
            sx={{bgcolor:'background.textBox', input: {color: 'text.primary'}}}
        />
        <Typography variant='h6' sx={{p:1}}>Your message</Typography>
        <TextField
            id="message"
            name="message"
            type="text"
            multiline
            rows={2}
            sx={{width:'100%', bgcolor:'background.textBox', input: {color: 'text.primary'}}}
        />
        <Button variant='contained' type="submit" sx={{mt:2}}>Send</Button>
    </Box>
    </Form>
    </Formik>
  );
};

export default EmailMeForm;