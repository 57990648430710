import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"


function KeyLogger() {
    const [keyEvent, setKeyEvent] = useState<KeyboardEvent | null >(null)
    
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            setKeyEvent(e);
        }
    
        window.addEventListener('keydown', handleKeyDown)
    
        return () => {
        window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])
    
    return (
        <Box>
            <Typography variant='h6' sx={{p:1}}>Key Logger</Typography>
            <Typography variant='body1' sx={{p:1}}>Key: {keyEvent? keyEvent.key : ""}</Typography>
            <Typography variant='body1' sx={{p:1}}>Code: {keyEvent? keyEvent.code : ""}</Typography>
            <Typography variant='body1' sx={{p:1}}>KeyCode: {keyEvent? keyEvent.keyCode : ""}</Typography>
            {keyEvent &&
                <Typography variant='body1' sx={{p:1}}>KeyRepeat: {keyEvent.repeat? "true" : "false"}</Typography>
            }
        </Box>

    )
}

export default KeyLogger;



