import React from 'react';
import { Box, Typography, Grid, Button, FormControl, InputLabel, Input, FormHelperText, TextField } from "@mui/material";
import { IconButton } from "material-ui";
import { Twitter, LinkedIn, GitHub } from '@mui/icons-material';
import { SubstackIcon } from "./icons";
import EmailMeForm from "./EmailMeForm";

const links = {
    twitter: "https://twitter.com/GilbertGravis", 
    substack: "https://gilbertgravis.substack.com/",
    linkedin: "https://www.linkedin.com/in/bryangilbertdavis/", 
    github: "https://github.com/grabblies"
}

const Contact = () => {
    const [emailFormVisible, setEmailFormVisible] = React.useState(false);
    const emailButtonContent = emailFormVisible ? "Hide" : "Email Me";


    return (
            
        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <Typography variant="h3" sx={{p:2}}>Contact</Typography>
            <Box sx={{display:'flex', alignItems:'center', flexDirection:'column'}}>
                <Typography variant="h6" sx={{p:1}}>Social</Typography>
                <Grid container spacing={5} justifyContent="space-evenly" alignItems="center">
                    <Grid item>
                        <a href={links.twitter}><Twitter color='secondary' fontSize='large' sx={{p:'inherit'}} /></a>
                    </Grid>
                    <Grid item>
                        <a href={links.substack}><SubstackIcon color='secondary' fontSize='medium'/></a>
                    </Grid>                    
                    <Grid item>
                        <a href={links.linkedin}><LinkedIn color='secondary' fontSize='large' /></a>
                    </Grid>
                    <Grid item>
                        <a href={links.github}><GitHub color='secondary' fontSize='large' /></a>
                    </Grid>
                </Grid>

                <Button sx={{m:2, width:150}} variant="contained" onClick={() => setEmailFormVisible(!emailFormVisible)}>{emailButtonContent}</Button>
                {emailFormVisible && <EmailMeForm />}


            </Box>
        </Box>
    )

}
export default Contact;

