import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, RouterProvider, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';

import MenuComponentMap from './MenuComponentMap';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// const browserRouter = createBrowserRouter([
//   {
//     path: '/',
//     element: <App />,
//   },
//   Object.keys(MenuComponentMap).map((page) => (
//     return {path}
// ]);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
