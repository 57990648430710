import * as React from 'react';

import { Link, Routes, Route, useParams} from "react-router-dom";


function Projects() {
    const projectName = useParams();
    return (
    <div>
            <Link to="/Projects/poo">poo</Link>
            <Link to="/Projects/boo">boo</Link>

    </div>
    )
}
export default Projects;