import { SvgIcon, SvgIconProps } from "@mui/material"


const substackPath = <path d="M22.539 8.242H1.46V5.406h21.08v2.836zM1.46 10.812V24L12 18.11 22.54 24V10.812H1.46zM22.54 0H1.46v2.836h21.08V0z"/>

// export const SubstackIcon = createSvgIcon(substackPath, "SubstackIcon")

export function SubstackIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            {substackPath}
        </SvgIcon>
    )
}