import { Typography, Box} from "@mui/material";


const Admin = () => {
    return (
        <Box sx={{ml:'80px'}}>
            <Typography variant='h3'>Admin</Typography>
        </Box>
    )

}
export default Admin;