import { Box, Button, Popper, Slide } from "@mui/material";
import { prototype } from "events";
import React from "react";
import { Link } from "react-router-dom";

import mandelbrot from '../assets/mandel-small.png'


interface PopOutMenuProps {
    pages: string[]
    startingPage: string
  }
  
function PopOutMenu(props:PopOutMenuProps) {
    const [open, setOpen] = React.useState(false);
    
    var buttonsDisabledStarting = new Array(props.pages.length).fill(false);
    buttonsDisabledStarting[props.pages.indexOf(props.startingPage)] = true
    const [buttonsDisabled, setButtonsDisabled] = React.useState(buttonsDisabledStarting)
    
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    
    const handlePopupMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      open ? setOpen(false) : setOpen(true);
    };

    const onMenuItemClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      var newButtonsDisabled = new Array(props.pages.length).fill(false);

      if (event.currentTarget.textContent !== null) {
        newButtonsDisabled[props.pages.indexOf(event.currentTarget.textContent)] = true;
        setButtonsDisabled(newButtonsDisabled);
      }
    };
    
    return (
      <Box sx={{ml:0}}> 
        <Button
        id="popout-menu"
        aria-controls={open ? 'popout-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handlePopupMenuClick}
        sx={{bgcolor:'primary.main', mr:0, width:80, height:80, flexDirection:'column', justifyContent:'center', alignItems:'center'}}
        >
          <Box component="img" src={mandelbrot} sx={{width:50, height:50, borderRadius:'40%'}}/>
        </Button>
        <Popper open={open} anchorEl={anchorEl} placement="right" >        
          <Slide direction ='right' in >
          
            <Box sx={{display:'flex', flexDirection:'row', color:'primary.main'}}>
              {props.pages.map((page) => (
                <Link key={page+"link"} to={`/${page}`} style={{textDecoration: 'none'}}>
                <Button
                    key={page}
                    variant="contained"
                    disabled={buttonsDisabled[props.pages.indexOf(page)]}
                    onClick={onMenuItemClick}
                    sx={{display:'flex', alignItems:'center', border:.5, m: 1 } }
                >
                  {page}
                </Button>
              </Link>
              ))}
              
            </Box>  
          </Slide>
        </Popper>
      </Box>

      
  )};

  export default PopOutMenu;