import React, { Component } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

import PopOutMenu from './components/PopOutMenu'
import MenuComponentMap from './MenuComponentMap';
import Admin from './components/Admin';

import { Link, Route, Routes } from 'react-router-dom'

function App() {
  const pages = Object.keys(MenuComponentMap);
  const [currentPage, setCurrentPage] = React.useState<string>(pages[0])

  const BGD = () => {
    return (  
    <Box
    sx={{bgcolor:'primary.main', 
    width:80, 
    height:80, 
    justifyContent:'center', 
    alignItems:'center',
    borderRadius:1}}
    >
      <Link to='/admin' style={{textDecoration:'none'}}>
      <Typography variant="h2" sx={{color:'primary.main', fontWeight:'bold', fontSize:20, textAlign:'center', m:0}}>
      BGD
      </Typography>
      </Link>
    </Box>
    )};



  return (
    <div className="App">
      <header className="App-header" >
        <Box sx={{flexGrow: 1 }}>
          <AppBar position="static" sx={{bgcolor:'primary.light'}}>
            <Toolbar sx={{justifyContent:'space-between', p:0}} >
              <PopOutMenu pages={Object.keys(MenuComponentMap)} startingPage={currentPage} />
              <BGD /> 
            </Toolbar>
          </AppBar> 
        </Box> 
        
        <Box>
        <Routes>
            {pages.map((page) => (
              <Route key={page} path={`/${page}`} element={React.createElement(MenuComponentMap[page])} />
              ))} 
            {pages.map((page) => (
              <Route key={page+'item'} path={`/${page}/:id`} element={React.createElement(MenuComponentMap[page])} />
            ))}
            <Route key="admin" path="/admin" element={<Admin/>} />
            <Route path="*" element={<div>Page Not Found</div>} />
        </Routes>
        </Box>

      </header>
    </div>
  );
}

export default App;
