import createTheme from "@mui/material/styles/createTheme";
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { orange, blue , grey } from "@mui/material/colors";

// default theme: https://mui.com/material-ui/customization/default-theme/

const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
      textBox: grey[100],
    },
    text: {
      primary: grey[900],
      secondary: grey[50],
    },
    action: {
      active: '#001E3C',
    },
    success: {
      main: '#009688',
    },
    primary:{
      main:blue[500],
      light:blue[300], 
      dark:blue[700], 
    },
    secondary:{
      main:orange[800],
      light:orange[500],
      dark:orange[900],

    },
    action:{
      active:blue[500],
      hover:blue[300],
      selected:blue[700],
      disabled:grey[500],
      disabledBackground:blue[500],
    },
  }, 
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200
    }
  },

  typography: {
    inactive: {
      color: '#gray',
      fontWeight: 600,    
    },
    secondary: {
      color:blue[100]
    },
    textBlock: {
      color: '#black',
      fontWeight: 600,
    }
    
  },

  components: {
      // Name of the component
      MuiButtonBase: {
        defaultProps: {
          // The props to change the default for.
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
      ToolBar: {
        defaultProps: {
            backgroundColor: "red",
      }
    },
    NavBar: {
        defaultProps: {
            backgroundColor: "red",
      }
    }
  }
});

export default theme;


