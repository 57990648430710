import About from './components/About';
import Contact from './components/Contact';
import Projects from './components/Projects';
import Utilities from './components/Utilities';


interface StringToComponent {
    [key: string]: React.ComponentType
}

const MenuComponentMap:StringToComponent = {
    'About': About,
    'Contact': Contact,
    'Projects': Projects,
    'Utilities': Utilities,
};

export default MenuComponentMap;